.main {
    position: relative;
    display: flex;
    align-items: center;
    flex-direction: column;
    margin-top: 20px;
}

.content{
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: 1440px;
    padding: 0px 10px;
}

.monthText{
    font-size: 18px;
}

.stateMonth{
    font-size: 12px;
    color: #5394fd;
    margin-bottom: 10px;
}

.monthBlock{
    margin-bottom: 40px;
}

.monthBlock:last-child{
    margin-bottom: 0px;
}

.elements{
    display: flex;
    justify-content:space-between;
    flex-wrap: wrap;
}

.headContent {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
}

.titleChart {
    font-size: 24px;
}

.productRation {
    padding: 4px;
    border-radius: 4px;
    margin-bottom: 4px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.productRationName {
    font-size: larger;
    text-align: center;
}

/* CODE */

.circular_progress {
    --size: 100px;
    --half-size: calc(var(--size) / 2);
    --stroke-width: 10px;
    --radius: calc((var(--size) - var(--stroke-width)) / 2);
    --circumference: calc(var(--radius) * 3.14 * 2);
    --dash: calc((var(--progress) * var(--circumference)) / 100);
}

.circular_progress, .circle {
    cx: var(--half-size);
    cy: var(--half-size);
    r: var(--radius);
    stroke-width: var(--stroke-width);
    fill: none;
    stroke-linecap: round;
}

.circular_progress, .bg {
    stroke: #ddd;
}

.bg{
    stroke-dasharray: 360;
}

.circular_progress, .fg {
    transform: rotate(-90deg);
    transform-origin: var(--half-size) var(--half-size);
    stroke-dasharray: var(--dash) calc(var(--circumference) - var(--dash));
    transition: stroke-dasharray 0.3s linear 0s;
    stroke: #5394fd;
}

@media (max-width: 640px) {
    .elements{
        justify-content: start;
    }
}