.psevdoBody{
    display: flex;
    justify-content: center;
    align-items: center;
}

.containerMain {
    margin: 20px;
    padding: 20px;
    background-color: #fff;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    border-radius: 5px;
    max-width: 1440px;
    width: 100%;
}

/* Стили для заголовков */
.customH1 {
    font-size: 28px;
    color: #333;
    margin-bottom: 20px;
}

.customH2 {
    font-size: 24px;
    color: #555;
    margin-top: 10px;
    margin-bottom: 10px;
}

/* Стили для формы */
.customForm {
    margin-bottom: 20px;
}

.containerJobValue {
    width: 100%;
    padding: 10px;
    margin-bottom: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
}

.containerJobDescription {
    width: 100%;
    padding: 10px;
    margin-bottom: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
}

.customSelect {
    width: 100%;
    padding: 10px;
    margin-bottom: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
}

.customButton {
    padding: 10px 20px;
    background-color: #007BFF;
    color: #fff;
    border: none;
    border-radius: 4px;
    cursor: pointer;
}

    .customButton:hover {
        background-color: #0056b3;
    }

/* Стили для списка данных */
.containerUl {
    list-style: none;
    padding: 0;
}

.buttonDelete {
    padding: 6px 20px;
    border: 1px solid transparent;
    border-radius: 4px;
    color: white;
    background-color: #ff3333;
    transition: 0.3s;
}

.buttonDelete:hover {
    border: 1px solid #ff3333;
    color: #ff3333;
    background-color: transparent;
    transition: 0.3s;
}

.buttonEdit {
    padding: 6px 20px;
    border: 1px solid transparent;
    border-radius: 4px;
    color: white;
    background-color: #4733ff;
    margin-right: 4px;
    transition: 0.3s;
}

.buttonEdit:hover {
    border: 1px solid #4733ff;
    color: #4733ff;
    background-color: transparent;
    transition: 0.3s;
}

.containerEvent {
    display: flex;
    flex-wrap: nowrap;
}

.containerNameItem {
    font-weight: bold;
}

.containerDescriptionItem {
    width: 180px;
    overflow: hidden;
    display: -webkit-box;
	-webkit-line-clamp: 1;
	-webkit-box-orient: vertical;
}

.containerLi {
    margin-bottom: 10px;
    padding: 10px;
    background-color: #f9f9f9;
    border: 1px solid #ccc;
    border-radius: 4px;

    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

/* Стили для кнопок "Редактировать" */
button.edit-button {
    background-color: #28a745;
}

    button.edit-button:hover {
        background-color: #1f9032;
    }

.boxPanelUp {
    position: absolute;
    top:0px;
    right:0px;
    left: 0px;
    bottom: 0px;

    background-color: #ffffff80;

    display: flex;
    justify-content: center;
    align-items: center;
}

.boxField {
    width: 100%;
    max-width: 420px;
    margin: 20px;
    border: 2px solid #98aba9;
    border-radius: 5px;
    padding: 20px;
    background-color: ivory;
    display: flex;
    flex-direction: column;
    align-items: stretch;
}

.nameFieldEdit {
    text-align: center;
    font-size: 25px;
    padding: 10px 20px;
}

.inputFieldEdit {
    text-align: center;
    padding: 10px 20px;
    margin-bottom: 20px;
}

.buttonComplete{
    margin-bottom: 5px;
}

.buttonCancel {
    background-color: white;
    color: #007BFF;
    border: 2px solid #007BFF;
    border-radius: 4px;
}

.buttonRed{
    background-color: white;
    border: 2px solid red;
    border-radius: 4px;
    color: red;
    margin-bottom: 5px;
}

    .buttonRed:hover {
        background-color: red;
        color: white;
    }

.buttonDisabled{
    border: 1px solid #999999;
    background-color: #cccccc;
    color: #666666;
}

.buttonDisabled:hover {
    cursor: default;
    border: 1px solid #999999;
    background-color: #cccccc;
    color: #666666;
}

.checkList{
    background-color: white;
    border: 2px solid transparent;
    border-radius: 4px;
    color: #666666;
}

.checkList:hover{
    background-color: white;
    border: 2px solid transparent;
    color: #666666;
}

.checkListShiftBody{
    position: absolute;
    top: 0px;
    left: 20px;
    right: 20px;
    bottom: 0px;
    background-color: #999999a1;

    display: flex;
    justify-content: center;
    align-items: center;
}

.checkListShift{
    padding: 12px 25px;
    display: flex;
    flex-direction: column;
    width: 80%;
    border-radius: 4px;
}